import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import ActiveItemGallery from "@components/ActiveItemGallery"

import {
  RequestMoreInfo,
} from "@components/Forms"

const galleryItems = [
  {
    key: 1,
    title: "Front Body",
    images: ["/images/guitar004-front-body.jpg"],
  },
  {
    key: 2,
    title: "Back Body",
    images: ["/images/guitar004-back-body.jpg"],
  },
  {
    key: 3,
    title: "Finished in Case",
    images: ["/images/guitar004-finished-in-case.jpg"],
  },
  {
    key: 4,
    title: "Unfinished",
    images: ["/images/guitar004-unfinished.jpg"],
  },
  {
    key: 5,
    title: "Shop Cat Millie Moo Helps Sand",
    images: ["/images/guitar004-sanding-with-shop-cat.jpg"],
  },
  {
    key: 6,
    title: "Randy Finishing With Tru-Oil",
    images: ["/images/guitar004-finishing.jpg"],
  },
  {
    key: 7,
    title: "Jimmy Ether's Test Drive",
    images: ["/images/guitar004-jimmy-ether-ethermaster.jpg"],
  },
]

const Guitar001 = () => {
  return (
    <PageLayout>
  <SEO
    title="Telemaster | Aluminum Neck | Tele Bridge and Filtertron"
    ogDescription="This Telemaster custom guitar with an EGC aluminum neck was a custom order: combining Jazzmaster style body with at Telecaster pickup and bridge setup. Let's build your dream guitar!"
  />
  <SingleColumnBlock
    textPadded
    text={
      <div className="content">
        <h1>Telemaster Custom Guitar With Aluminum Neck</h1>
        <p className="detail">
          <b>Guitar:</b> The Ethermaster
        </p>
        <p className="detail">
          <b>Finish:</b> Sapele and Zebrawood with Tru-Oil finish
        </p>
        <p className="detail">
          <b>Details:</b> Lolar Vintage T Bridge Pickup and TV Jones Filtertron pickups
        </p>
      </div>
    }
  />
  <ActiveItemGallery
    items={galleryItems}
  />
  <SingleColumnBlock
    textPadded
    text={
      <div>
        <h2>This Guitar's Story</h2>
        <p>
          This guitar was a custom order by <a href="https://jimmyether.com" target="_blank" rel="noreferrer">Jimmy Ether</a>. He wanted a Jazzmaster style body with at Telecaster pickup and bridge setup. But he also wanted the guitar to be modeled after the 70's era <a href="http://www.travisbeanguitars.com/" target="_blank" rel="noreferrer">Travis Bean</a> and Kramer guitars with aluminum necks. He provided the EGC aluminum neck. We went with a heavy Sapele wood to balance the neck weight. And we did center racing stripes in contrasting Zebrawood inspired by the 70's era Kramer guitars. The natual stripes in the Sapele with the Zebrawood was gorgeous, so I gave it a gun stock Tru-Oil finish. 
        </p>
        <h2>Specifications</h2>
        <ul>
          <li>Elecrical Guitar Company aluminum bolt-on neck</li>
          <li>25.5-inch Scale</li>
          <li>Sepele body with Zebrawood racing stripes</li>
          <li>Tru-Oil Finish</li>
          <li>Reversed Angled Switch Control Plate</li>
          <li>Gotoh Modern Bridge for Tele</li>
          <li>Custom Length Airline Screws for Extended Range Intonation</li>
          <li>Lolar Vintage T Bridge Pickup</li>
          <li>TV Jones "Ray Butts" Filtertron Neck Pickup</li>
          <li>CTS 500k Volume and 250k Tone Pots</li>
          <li>.022uf Orange Drop Cap</li>
          <li>Resistor for Filtertron Pairing with Tele Single Coil</li>
          <li>Switchcraft Switch and Output Jack</li>
          <li>Vintage Cloth Push Back Wire</li>
        </ul>
        <h2>Price and Availability</h2>
        <p className="red">
          <strong>SOLD</strong>
        </p>
      </div>
    }
  />
  <SingleColumnBlock
    textPadded
    text={
      <div>
        <h4>Want me to build you something like it? Let's talk!</h4>
        <RequestMoreInfo />
      </div>
    }
  />
    </PageLayout>
  )
}

export default Guitar001